import React, { useEffect, useState } from "react";
import {
  mobile_arrow_left,
  Subtract,
  true_blue,
  true_gray,
  right_arrow,
  black_accept_circle,
} from "../../utils/images";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import OrientationModal from "../../components/Popup/OrientationScheduledPopup";
import store from "../../rematch";
import moment from "moment";
import { removeCookie } from "../../utils";
import TimeSlot from "../../components/Toast/TimeSlot";
import { Loader } from "lucide-react";
export const covertUTCtolocal = (date, format) => {
  switch (format) {
    case "DATE":
      if (date) {
        return moment.utc(date).local().format("MM/DD/YYYY");
      }
      return;
    case "TIME":
      return moment.utc(date).local().format("h:mm A");
    default:
      break;
  }
};
export const convertlocaltoUTC = (date, day) => {
  return moment.utc(date, "MM/DD/YYYY").add(day, "days").toISOString();
};
export function getDurationInHoursMinutes(startDate, endDate) {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (hours || minutes) {
    return `${hours.toString().padStart(2, "0")} hr ${minutes
      .toString()
      .padStart(2, "0")} ${minutes === 0 ? "min" : "mins"}`;
  } else {
    return `--`;
  }
}
const PrematchTodo = () => {
  const preMatchDetails = useSelector(
    (state) => state.preMatchModel.preMatchDetails?.userObj
  );
  const { userObj } = useSelector(
    (state) => state.onBoardingModel.onboardingSteps
  );
  const loading = useSelector(
    (state) => state.loading.effects?.preMatchModel?.getPreMatchDetails
  );
  const [showModal, setShowModal] = useState(0);
  const { role } = useSelector((state) => state.authModel?.logInUser);
  const toaster = useSelector((state) => state.toastModel);
  const MentorData = [
    {
      id: 0,
      step: "Step 1",
      title: "Complete your application",
      completedTitle: "Complete your application",
    },
    preMatchDetails?.isOrientation && {
      id: 1,
      step: "Step 2",
      title: "Schedule Orientation",
      completedTitle: "Orientation Scheduled",
    },
    preMatchDetails?.isScreening && {
      id: 2,
      step: preMatchDetails?.isOrientation ? "Step 3" : "Step 2",
      title: "Complete Screening",
      completedTitle: "Screening Scheduled",
    },
    preMatchDetails?.isAssignCourse && {
      id: 3,
      step: preMatchDetails?.isOrientation
        ? preMatchDetails?.isScreening
          ? "Step 4"
          : "Step 3"
        : preMatchDetails?.isScreening
        ? "Step 3"
        : "Step 2",
      title: "Complete Training",
      completedTitle: "Training Completed",
    },
  ];
  const MenteeData = [
    {
      id: 0,
      step: "Step 1",
      title: "Complete your application",
      completedTitle: "Complete your application",
    },
    {
      id: 1,
      step: "Step 2",
      title: "Complete Onboarding",
      completedTitle: "Onboarding Completed",
    },
  ];
  const { dispatch } = store;
  const navigate = useNavigate();

  useEffect(() => {
    if (role == "Mentor") {
      dispatch.preMatchModel.getPreMatchDetails({});
    } else if (role == "Mentee") {
      dispatch.preMatchModel.getMenteePreMatchDetails({});
    }
  }, [role]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleNavigation = (id) => {
    if (role == "Mentor") {
      if (preMatchDetails.preMatchStep > id) {
        switch (id) {
          case 1:
            setShowModal(1);
            break;
          case 2:
            setShowModal(2);
            break;
          default:
            break;
        }
      }
      if (preMatchDetails.preMatchStep == 3 && id == 3) {
        dispatch.preMatchModel.getMentorTraining();
      }
      if (preMatchDetails.preMatchStep == id && !(id === 3)) {
        handleNavigate(`/home/step${id + 1}`);
      }
    } else {
      if (
        preMatchDetails.preMatchStep == id &&
        !(preMatchDetails.preMatchStep > 3)
      ) {
        handleNavigate(`/home/completeonboarding`);
      }
    }
  };

  const handleContinue = () => {
    if (role == "Mentor") {
      if (preMatchDetails?.preMatchStep == 4) {
        const payload = {
          step: 5,
          isDraft: false,
          schedule_id: "65609b2c63596ff589d24fe8",
          reschedule: false,
        };
        dispatch.preMatchModel?.postDetails({
          payload,
          handleNavigate,
          key: "",
        });
        // handleNavigate("/home/congratulation");
      }
    } else {
      if (preMatchDetails?.preMatchStep == 3) {
        dispatch.preMatchModel.postMenteeDetails({
          payload: { step: 4, isDraft: false, reschedule: false },
          handleNavigate,
        });
        // handleNavigate("/home/congratulation");
      }
    }
  };
  const handleSaveAndExit = () => {
    removeCookie("userDetails");
    store.dispatch({ type: "RESET" });
    navigate("/");
  };
  let preMatchData;
  if (role == "Mentor") {
    // if(preMatchDetails?.isAssignCourse){
    //   preMatchData = MentorData}
    //   else if(!preMatchDetails?.isScreening){
    //     preMatchData = MentorData.splice(2, 1);
    //   }else{
    //   preMatchData = MentorData.slice(0,3);
    // }
    preMatchData = MentorData;
  } else {
    preMatchData = MenteeData;
  }
  return (
    <div className="iMentor_web_sec custome_bg_img">
      <div className="container">
        <div className="prematch_wrapper">
          {/* <div className="back_home_mobile">
          <img src={mobile_arrow_left} alt="" />
        </div> */}
          <h2>Pre-Match To Do List</h2>
          <p>
            We match mentors and mentees based on shared interests, experiences
            and goals. Complete these steps to meet your perfect matches.
          </p>
          {loading ? (
            <>
              <div className="min-h-[430px] d_flex_center">
                <Loader className="animate-spin mr-2 inline-block" />
              </div>
            </>
          ) : (
            preMatchDetails && (
              <div className="prematch_step">
                <ul>
                  {preMatchData?.map((step) => {
                    if (typeof step === "object") {
                      return (
                        <li key={step.id}>
                          <div
                            className={`step_info d_flex align_items_start ${
                              preMatchDetails.preMatchStep > step.id
                                ? "completed"
                                : preMatchDetails.preMatchStep == step.id
                                ? "schedule"
                                : "pending"
                            }`}
                          >
                            <img
                              className="complete_icon"
                              src={Subtract}
                              alt=""
                            />
                            <img className="blue_icon" src={true_blue} alt="" />
                            <img className="gray_icon" src={true_gray} alt="" />
                            <h4>
                              {step.step}
                              <span
                                onClick={() => handleNavigation(step.id)}
                                className={
                                  preMatchDetails.preMatchStep == step.id
                                    ? "active"
                                    : ""
                                }
                              >
                                {preMatchDetails.preMatchStep > step.id
                                  ? step.completedTitle
                                  : step.title}
                              </span>
                            </h4>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
      {role == "Mentor" ? (
        <>
          {preMatchDetails?.preMatchStep === preMatchData?.length  && (
            <div class="pre_match_footer border-bottom">
              <div class="footer_area_section">
                <ul class="footer_wrapper submit_btns d_flex_center">
                  <li>
                    <p>
                      {/* Need Help?{" "}
                      <a
                        onClick={() => {
                          window.top.location = "mailto:hello@imentor.org";
                        }}
                        href="mailto:hello@imentor.org"
                      >
                        Contact Us
                      </a> */}
                      Need Help?{" "}
                      <a
                        className=" font-semibold"
                        onClick={() => {
                          window.top.location = `mailto:${
                            userObj?.partnerAdmin
                              ? userObj?.partnerAdmin?.contactEmail
                              : userObj?.region?.contactEmail
                          }`;
                        }}
                        href={`mailto:${
                          userObj?.partnerAdmin
                            ? userObj?.partnerAdmin?.contactEmail
                            : userObj?.region?.contactEmail
                        }`}
                      >
                        Contact Us
                      </a>
                    </p>
                  </li>
                  <li class="d_flex step_info ">
                    <button class="upload_img" onClick={handleSaveAndExit}>
                      <img src={black_accept_circle} alt="" />
                      Save & Exit
                    </button>
                    <button
                      class="btn continue_btn d_flex_center"
                      type="submit"
                      onClick={handleContinue}
                    >
                      Continue
                      <img src={right_arrow} alt="" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {preMatchDetails?.preMatchStep === 3 && (
            <div class="pre_match_footer border-bottom">
              <div class="footer_area_section submit_btns">
                <ul class="d_flex_center footer_wrapper">
                  <li>
                    <p>
                      {/* Need Help?{" "}
                      <a
                        onClick={() => {
                          window.top.location = "mailto:hello@imentor.org";
                        }}
                        href="mailto:hello@imentor.org"
                      >
                        Contact Us
                      </a> */}
                      Need Help?{" "}
                      <a
                        className=" font-semibold"
                        onClick={() => {
                          window.top.location = `mailto:${
                            userObj?.partnerAdmin
                              ? userObj?.partnerAdmin?.contactEmail
                              : userObj?.region?.contactEmail
                          }`;
                        }}
                        href={`mailto:${
                          userObj?.partnerAdmin
                            ? userObj?.partnerAdmin?.contactEmail
                            : userObj?.region?.contactEmail
                        }`}
                      >
                        Contact Us
                      </a>
                    </p>
                  </li>
                  <li class="d_flex step_info">
                    <button class="upload_img" onClick={handleSaveAndExit}>
                      <img src={black_accept_circle} alt="" />
                      Save & Exit
                    </button>
                    <button
                      class="btn continue_btn d_flex_center"
                      type="submit"
                      onClick={handleContinue}
                    >
                      Continue
                      <img src={right_arrow} alt="" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      {showModal == 1 && (
        <OrientationModal
          details={preMatchDetails?.scheduleOrientation}
          setShowModal={setShowModal}
          title={"Orientation Scheduled"}
          step={2}
        />
      )}
      {showModal == 2 && (
        <OrientationModal
          details={preMatchDetails?.completeScreening}
          setShowModal={setShowModal}
          title={"screening Scheduled"}
          step={3}
        />
      )}
      <TimeSlot
        toastList={toaster.timeSlotList}
        autoDelete={true}
        autoDeleteTime={3000}
      />
    </div>
  );
};

export default PrematchTodo;

import { lazy } from "react";
import { ROLE } from "../static";

//publicRoute
const Login = lazy(() => import("../pages/Login"));
const Registration = lazy(() => import("../pages/Registration"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const InquiryForm = lazy(() => import("../pages/InquiryForm"));
const ThankYou = lazy(() => import("../pages/InquiryForm/ThankYou/ThankYou"));

//privateRoute
const Home = lazy(() => import("../pages/Home"));
const SignupSuccess = lazy(() => import("../pages/SignupSuccess"));

const Step2 = lazy(() => import("../pages/Step2"));
const Step3 = lazy(() => import("../pages/Step3"));
const Mentees = lazy(() => import("../pages/Mentees"));
const Messages = lazy(() => import("../pages/Messages"));
const Profile = lazy(() =>
  import("../pages/Mentees/Profile/Profile")
);
const PrematchTodo = lazy(() => import("../pages/PrematchTodo"));
const BasicInfo = lazy(() => import("../pages/OnboardingSteps/BasicSteps"));
const AdditionalInfo = lazy(() =>
  import("../pages/OnboardingSteps/AdditionalInfoStep")
);
const MatchingInfo = lazy(() =>
  import("../pages/OnboardingSteps/MatchingQuestions")
);

const Congratulation = lazy(() =>
  import("../pages/PrematchTodo/Congratulation")
);
const Calendar = lazy(() => import("../pages/Home/Calendar"));
const ApplicationComplete = lazy(() => import("../pages/ApplicationComplete"));
const Event = lazy(() => import("../pages/Event"));
const EventDetais = lazy(() => import("../pages/Event/EventDetailsForm"));
const Progress = lazy(() => import("../pages/Progress"));
const ProgressDetail = lazy(() => import("../pages/Progress/Progress_details"));
const Content = lazy(() => import("../pages/Content"));
const CompleteOnboarding = lazy(()=>import("../pages/Step2/CompleteOnboarding"))
const ContentFullViewMobile = lazy(()=>import("../pages/Content/ContentFullViewMobile"))
const ProjectFullView = lazy(()=>import("../pages/Content/Projects/ProjectsFullView"))

const { MENTOR, MENTEE } = ROLE;

export const publicRouteList = [
  {
    path: "/inquiry-form/:id",
    element: InquiryForm,
    title: "InquiryForm",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/thankyou",
    element: ThankYou,
    title: "thankyou",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/",
    element: Login,
    title: "Login",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/register",
    element: Registration,
    title: "Registration",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
    title: "ForgotPassword",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/change-password",
    element: ChangePassword,
    title: "ChangePassword",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
  {
    path: "/inquiry-form",
    element: InquiryForm,
    title: "InquiryForm",
    allowedRoles: [MENTEE, MENTOR],
    layout: "AUTH_LAYOUT",
  },
];

export const privateRouteList = [
  {
    path: "/home",
    element: Home,
    title: "Home",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path:"/home/calendar",
    element:Calendar,
    title:"Calendar",
    allowedRoles:[MENTEE,MENTOR],
    isBack: true,
  },
  {
    path: "/home/pre-match-todo",
    element: PrematchTodo,
    title: "Pre-match-todo",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/home/step2",
    element: Step2,
    title: "Step2",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/home/step3",
    element: Step3,
    title: "Step3",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/home/congratulation",
    element: Congratulation,
    title: "Congratulation",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/events",
    element: Event,
    title: "Events",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/events/eventdetails",
    element: EventDetais,
    title: "Event",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/mentees",
    element: Mentees,
    title: "mentees",
    allowedRoles: [MENTOR],
    isBack: true,
  },
  {
    path: "/mentors",
    element: Mentees,
    title: "mentors",
    allowedRoles: [MENTEE],
  },
   {
    path: "/mentors/:id",
    element: Profile,
    title: "mentors",
    allowedRoles: [MENTEE],
  },
  {
    path: "/messages/:userId",
    element: Messages,
    title: "Messages",
    allowedRoles: [MENTEE, MENTOR],
    isBack: false,
    className: "sticky_comman_header"
  },
  {
    path: "/messages",
    element: Messages,
    title: "Messages",
    allowedRoles: [MENTEE, MENTOR],
    isBack: false,
    className: "sticky_comman_header"
  },
  {
    path: "/mentees/:id",
    element: Profile,
    title: "mentees",
    allowedRoles: [MENTOR],
    isBack: true,
  },
  // {
  //   path: "/progress",
  //   element: Progress,
  //   title: "Progress",
  //   allowedRoles: [MENTEE, MENTOR],
  //   isBack: true,
  // },
  // {
  //   path: "/progress/:id",
  //   element: ProgressDetail,
  //   title: "ProgressDetail",
  //   allowedRoles: [MENTEE, MENTOR],
  //   isBack: true,
  // },
  {
    path: "/content",
    element: Content,
    title: "Content",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/content/fullview",
    element: ContentFullViewMobile,
    title: "ContentFullViewMobile",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/content/projectFullView",
    element: ProjectFullView,
    title: "ProjectFullView",
    allowedRoles: [MENTEE, MENTOR],
    isBack: true,
  },
  {
    path: "/home/completeonboarding",
    element: CompleteOnboarding,
    title: "CompleteOnboarding",
    allowedRoles: [MENTEE],
    isBack: true,
  },
  
];

export const registerRouteList = [
  {
    path: "/signup-success",
    element: SignupSuccess,
    title: "SignupSuccess",
    layout: "ONBOARDING_LAYOUT",
    allowedRoles: [MENTEE, MENTOR],
  },
  {
    path: "/application-complete",
    element: ApplicationComplete,
    title: "ApplicationComplete",
    layout: "ONBOARDING_LAYOUT",
    allowedRoles: [MENTEE, MENTOR],
  },
  {
    path: "/basic-info",
    element: BasicInfo,
    title: "Onboarding Steps",
    allowedRoles: [MENTEE, MENTOR],
    layout: "ONBOARDING_LAYOUT",
  },

  {
    path: "/additional-info",
    element: AdditionalInfo,
    title: "Onboarding Steps",
    allowedRoles: [MENTEE, MENTOR],
    layout: "ONBOARDING_LAYOUT",
  },

  {
    path: "/matching-questions",
    element: MatchingInfo,
    title: "Onboarding Steps",
    allowedRoles: [MENTEE, MENTOR],
    layout: "ONBOARDING_LAYOUT",
  },
];

import axiosClient from "../../api/axiosClient";

const eventModel = {
  state: {
    eventsId: "",
    addEventDetails: {},
    eventDetailsInfo: {},
    eventList: [],
    guestList: [],
    guestListLength: 0,
    isVirtual: false,
    draftScheduleEvent: {},
    updateScheduleEvent: {},
    isUpdate: false,
    isDelted: false,
    isEventList: false,
    event_type: "",
    page: 1,
    limit: 10,
    getNotificationList: {},
    editScheduleEvent: {},
    scheduleEventToggle: false,
    pagination: {
      limit: 10,
      page: 1,
      pages: 1,
      total: 0,
    },
    eventThumbnailIcons:[]
  },
  reducers: {
    setData(state, payload) {
      return { ...state, ...payload };
    },
    setPage(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: payload,
        },
      };
    },
    setPageLimit(state, payload) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: payload,
          page: 1,
        },
      };
    },
    setIsDrafted(state, isDrafted) {
      return { ...state, isDrafted };
    },
  },
  effects: (dispatch) => ({
    async getEvents(payload) {
      await axiosClient
        .post("/event/scheduleEventList", payload)
        .then((res) => {
          if (res.status === 200) {
            const { scheduledEvent, limit, page, pages, total } = res.data.data;
            const pagination = { limit, page, pages, total };
            this.setData({
              eventList: scheduledEvent,
              pagination,
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async addInFavoriteEvent(payload) {
      await axiosClient
        .post("event/addInFavoriteEvent", payload)
        .then((res) => {
          if (res.status === 200) {
            // console.log("sucess");
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async editScheduleEvent(payload) {
      await axiosClient
        .post("event/editScheduleEvent", payload)
        .then((res) => {
          if (res.status === 200) {
            this.setData({
              editScheduleEvent: res?.data?.data?.scheduleEvent,
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async eventGuestApproval(payload) {
      await axiosClient
        .post("event/eventGuestApproval", payload)
        .then((res) => {
          if (res.status === 200) {
            // console.log('sucess')
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async addEventDetails(payload) {
      return axiosClient
        .post("event/scheduleNewEvent", payload)
        .then((res) => {
          if (res.status === 200) {
            // console.log("sucess");
            this.getEvents({ event_type: "myEvent", page: 1, limit: 10 });
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async getGuestMentees(payload) {
      await axiosClient.post("event/getGuestList", payload).then((res) => {
        if (res.status === 200) {
          this.setData({
            guestList: res.data.data.GuestList,
            guestListLength: res.data.data.totalGuest,
          });
        }
      });
    },
    async getdraftScheduleEvent() {
      await axiosClient.get("event/draftScheduleEvent").then((res) => {
        if (res.status === 200) {
          this.setData({
            draftScheduleEvent: res?.data?.data?.scheduleEvent,
          });
        }
      });
    },

    async useUpdateScheduleEvent(payload) {
      await axiosClient
        .post("event/updateScheduleEvent", payload)
        .then((res) => {
          if (res.status === 200) {
            if (res.status === 200) {
              this.getEvents({ event_type: "myEvent", page: 1, limit: 10 });
            }
          }
        });
    },

    async useDeleteScheduleEvent(payload) {
      await axiosClient
        .post("event/deleteScheduleEvent", payload)
        .then((res) => {
          if (res.status === 200) {
            this.getEvents({ event_type: "myEvent", page: 1, limit: 10 });
          }
        });
    },

    async uploadEventAttachment(payload) {
      await axiosClient.post("event/uploadEventAttachment", payload);
    },

    async getScheduledEvent({ payload, status,guestStatus, isAdminAccess }) {
      await axiosClient.post("event/getScheduledEvent", payload).then((res) => {
        if (res.status === 200) {
          this.setData({
            eventDetailsInfo: {
              ...res.data?.data?.scheduledEvent,
              status,
              guestStatus,
              isAdminAccess,
            },
            eventsId: payload.eventId,
            scheduleEventToggle: true,
          });
        }
      });
    },
    async getEventThumbnailIcons() {
      await axiosClient.get("event/imageLibraryList").then((res) => {
        if (res.status === 200) {
          this.setData({
            eventThumbnailIcons:res.data.data});
        }
      });
    },
  }),
};

export default eventModel;

import {
  Decryption,
  Encryption,
  cookieDecryption,
  cookieEncryption,
  expireTime,
} from "../../utils";
import socketIo from "socket.io-client";
import Cookies from "js-cookie";
// import SentEmailPopup from "/components/Popup/SentEmailPopup";
import SentEmailPopup from "../../components/Popup/SentEmailPopup";
import SessionExpiredPopup from "../../components/Popup/SessionExpiredPopup";

export const getResponse = (data) => {
  return process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
    ? data
    : Decryption(data);
};

const requestHandler = (event, data) => {
  switch (event) {
    case "join": {
      return data;
    }
    default: {
      return { data, event };
    }
  }
};

const getChangedLoader = (event) => {
  switch (event) {
    case "getMsg": {
      return "chatLoader";
    }
    default: {
      return "nothing";
    }
  }
};

const socketModel = {
  state: {
    socketConnected: false,
    socketLoaders: {
      join: false,
      getMsgList: false,
      sendMsg: false,
      getMsg: false,
      getNotifications: false,
      chatLoader: false,
    },
  },
  reducers: {
    setConnected(state, payload) {
      state = { ...state, socketConnected: payload };
      return state;
    },
    setSocket(state, payload) {
      state = { ...state, socket: payload };
      return state;
    },
    setLoader(state, payload) {
      state = {
        ...state,
        socketLoaders: {
          ...state.socketLoaders,
          [payload.name]: payload.data,
        },
      };
      return state;
    },
  },
  effects: (dispatch) => ({
    getSocket({ token, userData }, state) {
      // const sock = socketIo('http://172.20.11.171:4000', {
      // const sock = socketIo('https://cmsapi.imentor.org/', {
      const sock = socketIo(process.env.REACT_APP_SOCKET_API_KEY, {
        transports: ["websocket"],
        // query: `user=${token}`,
      });
      sock.on("connect", () => {
        const payload = {
          event: "join",
          data: {
            user_id: userData?.userId,
            token: userData?.token,
          },
        };
        dispatch.socketModel.sendEvent({ payload });
      });
      this.setSocket(sock);
    },
    sendEvent({ payload, changeLoader }, state) {
      const { event, data } = payload;
      const socket = state?.socketModel?.socket;
      if (!changeLoader) {
        this.setLoader({ name: event, data: true });
      } else {
        this.setLoader({ name: changeLoader, data: true });
      }

      if (!socket?.connected) {
        return;
      }

      socket?.emit(
        event === "join" ? "join" : "request",
        process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
          ? requestHandler(event, data)
          : Encryption(requestHandler(event, data))
      );
    },
    socketResponse({ response }, state) {
      this.setLoader({ name: response?.event, data: false });
      this.setLoader({ name: getChangedLoader(response?.event), data: false });
      switch (response?.event) {
        // case "getMsgList": {
        //   dispatch.eventModel.setData({ getNotificationList: response?.data });
        //   break;
        // }
        case "join": {
          this.setConnected(true);
          // const payload = {
          //   event: "getCounts",
          //   data: {},
          // };
          // dispatch.socketModel.sendEvent({ payload });
          break;
        }
        case "error": {
          if (state.messagesModel.isErrorOccured) {
            break;
          }
          dispatch.messagesModel.setMsgState({
            name: "isErrorOccured",
            data: true,
          });
          dispatch.dialogModel.showDialog({
            component: () => <SessionExpiredPopup />,
            className: "alert_popup_height",
          });
          break;
        }
        case "getMsgList": {
          const userId = state.messagesModel.chatId;
          const logInUser = state.authModel.logInUser;

          let temp = response?.data?.find(
            (_) =>
              _?.users?._id === userId &&
              _?.lastMessage?.senderId !== logInUser?.userId &&
              !_?.lastMessage?.read
          );
          const payload = {
            event: "getMsg",
            data: {
              senderId: userId,
              limit: temp?.unRead > 10 ? temp?.unRead : 10,
            },
          };
          const isMsg =
            typeof temp?.lastMessage?.senderId === "string"
              ? temp?.lastMessage?.senderId
              : temp?.lastMessage?.senderId?._id;

          let conList = response?.data;
          if (isMsg === userId) {
            const payload = {
              event: "readMsg",
              data: { chId: temp?.chId, senderId: isMsg },
            };
            dispatch.socketModel.sendEvent({ payload });
            conList = conList?.map((e) =>
              e?.users?._id === userId ? { ...e, unRead: 0 } : { ...e }
            );
          }
          if (userId) {
            dispatch.socketModel.sendEvent({ payload });
          }
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: conList,
          });
          break;
        }
        case "sendMsg": {
          const newMessage = response.data.conversationlist;
          const userId = state.messagesModel.chatId;
          const list = state.messagesModel.connectionList;
          const logInUser = state.authModel.logInUser;
          const msgState = state.messagesModel.messageList || {};
          let temp = [...state.messagesModel.connectionList];
          // if (newMessage.chId !== state.messagesModel.userChId) {
          //   return
          // }
          let index = temp.findIndex((data) => {
            return data?.chId === newMessage.chId;
          });
          if (index > -1) {
            let latestChat = {
              ...temp[index],
              lastMessage: newMessage,
              unRead:
                userId !== newMessage?.receiverId?._id
                  ? temp[index]?.unRead + 1
                  : 0,
            };
            temp.splice(index, 1);
            temp.unshift(latestChat);
          } else {
            temp.unshift({
              ...newMessage,
              receiverId: newMessage.receiverId._id,
              users:
                newMessage.receiverId?._id === state.authModel.logInUser?.userId
                  ? newMessage.senderId
                  : newMessage.receiverId,
              lastMessage: newMessage,
              unRead: newMessage?.lastMessage?.read ? 0 : 1,
            });
          }
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: temp,
          });

          const updatedData = {
            ...msgState,
            messageData: [...(msgState?.messageData || []), newMessage],
            sendMessage: true,
          };

          if (userId === newMessage.senderId._id) {
            const payload = {
              event: "readMsg",
              data: { chId: newMessage?.chId, senderId: userId },
            };

            dispatch.socketModel.sendEvent({ payload });

            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: list?.map((e) => {
                return e?.users?._id === userId
                  ? { ...e, unRead: 0, lastMessage: { ...newMessage } }
                  : { ...e };
              }),
            });
          }

          if (newMessage.senderId._id === logInUser.userId) {
            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
          }

          break;
        }
        case "getMsg": {
          const msgState = state.messagesModel.messageList || {};
          const flatlist = msgState?.messageData?.flat();
          const list = state.messagesModel.connectionList;
          const messageData = [
            ...(response?.data?.conversationlist?.data || []).reverse(),
            ...(flatlist || []),
          ];
          let checkDuplicateData = messageData?.filter((obj, index) => {
            if (obj?.length > 0) {
              return true;
            }
            return index === messageData.findIndex((o) => obj._id === o._id);
          });
          let imgArray = [];
          let fileArray = [];
          // let testarray2 = checkDuplicateData?.reduce(
          //   (acc, cur, currentIndex) => {
          //     if (cur?.length > 0) {
          //       acc.push(cur);
          //       return acc;
          //     }
          //     if (cur?.chType === "Pair" && cur?.msg_type === "Media") {
          //       imgArray.push(cur);
          //     } else if (cur?.chType === "Pair" && cur?.msg_type === "File") {
          //       fileArray.push(cur);
          //     } else {
          //       if (imgArray.length > 0) {
          //         acc.push(imgArray);
          //         imgArray = [];
          //       }
          //       if (fileArray.length > 0) {
          //         acc.push(fileArray);
          //         fileArray = [];
          //       }
          //       acc.push({ ...cur });
          //     }
          //     if (
          //       currentIndex === checkDuplicateData.length - 1 &&
          //       imgArray.length > 0
          //     ) {
          //       acc.push(imgArray);
          //     }
          //     if (
          //       currentIndex === checkDuplicateData.length - 1 &&
          //       fileArray.length > 0
          //     ) {
          //       acc.push(fileArray);
          //     }
          //     return acc;
          //   },
          //   []
          // );

          let testarray = (checkDuplicateData || []).reduce(
            (acc, cur, currentIndex) => {
              if (cur && cur.length > 0) {
                acc.push(cur);
                return acc;
              }
              if (cur && cur.chType === "Pair" && cur.msg_type === "Media") {
                imgArray.push(cur);
              } else if (
                cur &&
                cur.chType === "Pair" &&
                cur.msg_type === "File"
              ) {
                fileArray.push(cur);
              } else {
                if (fileArray.length > 0) {
                  acc.push([...fileArray]);
                  fileArray = [];
                }
                if (imgArray.length > 0) {
                  acc.push([...imgArray]);
                  imgArray = [];
                }
                acc.push({ ...cur });
              }
              if (
                currentIndex === checkDuplicateData.length - 1 &&
                imgArray.length > 0
              ) {
                acc.push([...imgArray]);
              }
              if (
                currentIndex === checkDuplicateData.length - 1 &&
                fileArray.length > 0
              ) {
                acc.push([...fileArray]);
              }
              return acc;
            },
            []
          );
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: {
              ...(response?.data?.conversationlist || {}),
              ...response.data,
              messageData: testarray,
            },
          });
          let temp = response?.data?.conversationlist?.data?.at(-1);
          const userId = state.authModel.logInUser?.userId;

          if (userId !== temp?.senderId?._id && temp?.read === false) {
            const payload = {
              event: "readMsg",
              data: { chId: temp?.chId, senderId: temp?.senderId?._id },
            };
            dispatch.socketModel.sendEvent({ payload });
            dispatch.messagesModel.setMsgState({
              name: "connectionList",
              data: list?.map((e) => {
                return testarray[0].chId === e?.chId
                  ? { ...e, unRead: 0 }
                  : { ...e };
              }),
            });
          }
          break;
        }
        case "getNotifications": {
          dispatch.eventModel.setData({ getNotificationList: response?.data });
          break;
        }
        case "notification": {
          const filteredArray =
            state?.eventModel?.getNotificationList?.data?.filter(
              (notification) => {
                if (
                  (notification?.type === "InvitationDeclined" ||
                  notification?.type === "InvitationApproved") && notification?.dataId === response?.data?.dataId
                ) {
                  return (notification.from ?? notification?.from_id) !== response?.data?.from_id;
                }
                return true
              }
            );
          dispatch.eventModel.setData({
            getNotificationList: {
              ...state?.eventModel?.getNotificationList,
              data: [response?.data, ...filteredArray],
            },
          });
          // dispatch.eventModel.setData({
          //   getNotificationList: {
          //     ...state?.eventModel?.getNotificationList,
          //     data: [
          //       response?.data,
          //       ...state?.eventModel?.getNotificationList?.data,
          //     ],
          //   },
          // });
          break;
        }
        case "sendMsgReaction": {
          let data = response.data;
          const messageList = state.messagesModel.messageList || {};

          let index = messageList.messageData.findIndex(
            (_) => _._id === data.messageId
          );
          let newReactions =
            messageList?.messageData?.[index]?.reactions[0] === data.reaction
              ? []
              : [data.reaction];

          let updatedData = {
            ...messageList,
            messageData: messageList.messageData?.map((e) => {
              if (e.length) {
                return e;
              }
              return data.messageId === e?._id
                ? { ...e, reactions: newReactions }
                : { ...e };
            }),
            // {
            //   ...messageList.messageData[index],
            //   reactions: newReactions,
            // },
            // ...messageList.messageData.slice(index + 1),
            // messageData: [
            //   ...messageList.messageData.slice(0, index),
            //   {
            //     ...messageList.messageData[index],
            //     reactions: newReactions,
            //   },
            //   ...messageList.messageData.slice(index + 1),
            // ],
            sendReaction: true,
          };
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: updatedData,
          });
          dispatch.messagesModel.setMsgState({
            name: "connectionList",
            data: state.messagesModel.connectionList?.map((e) =>
              e?.lastMessage?.chId === response?.lastMessage?.chId
                ? { ...e, lastMessage: { ...response?.lastMessage } }
                : { ...e }
            ),
          });

          break;
        }
        case "getCounts": {
          dispatch.eventModel.setData({ getNotificationCount: response?.data });
          break;
        }

        case "readNotification": {
          if (response?.success) {
            const payload = {
              event: "getNotifications",
              data: { page: 1, limit: 10 },
            };
            // dispatch.socketModel.sendEvent({ payload });
            // const payloadCount = {
            //   event: "getCounts",
            //   data: {},
            // };
            // dispatch.socketModel.sendEvent({ payloadCount });
          }
          break;
        }
        case "matchedFound": {
          dispatch.homeModel.setData({
            name: "matchedPopup",
            data: !state.homeModel.matchedPopup,
          });
          dispatch.homeModel.setData({
            name: "matchedData",
            data: response?.data,
          });
          break;
        }
        case "readMsg": {
          let updatedData = {
            ...state?.messagesModel?.messageList,
            messageData: state?.messagesModel?.messageList?.messageData?.map(
              (e) => {
                if (e.length) {
                  return e;
                }
                return { ...e, read: true };
              }
            ),
          };
          dispatch.messagesModel.setMsgState({
            name: "messageList",
            data: updatedData,
          });

          // dispatch.messagesModel.setMsgState({
          //   name: "connectionList",
          //   data: state?.messagesModel?.connectionList?.map((e) => {
          //     console.log(e, "_______________________");
          //     e?.chId === response.data.chId
          //       ? { ...e, lastMessage: { ...e?.lastMessage, read: true } }
          //       : { ...e };
          //   }),
          // });
          break;
        }
        case "lessonCompleted": {
          if (state.authModel.logInUser.role == "Mentor") {
            // dispatch.preMatchModel.getPreMatchDetails({});
          } else if (state.authModel.logInUser.role == "Mentee") {
            // dispatch.preMatchModel.getMenteePreMatchDetails({});
          }
          state.preMatchModel.redirectURLWin.close();
          break;
        }

        case "userInactivated": {
          if (
            (state.authModel.logInUser?.userId,
            state?.messagesModel?.messageList?.messageData?.[0]?.receiverId
              ?._id)
          ) {
            let temp = state?.messagesModel?.messageList?.messageData;
            temp[0] = {
              ...temp[0],
              receiverId: {
                ...temp[0].receiverId,
                isDel: response?.data?.isDel,
                isDisabled: response?.data?.isDisabled,
              },
            };
            let updatedData = {
              ...state?.messagesModel?.messageList,
              messageData: temp,
            };
            dispatch.messagesModel.setMsgState({
              name: "messageList",
              data: updatedData,
            });
          }
          break;
        }

        case "progressPercentage": {
          if (state.contentProgressModel.contentList?.length > 0) {
            dispatch.contentProgressModel.setData({
              contentList: state.contentProgressModel.contentList?.map((e) =>
                e?.enrollId === response?.data?.enrollId
                  ? {
                      ...e,
                      percentage_completed:
                        response?.data?.progressInPercentage,
                      completed:
                        response?.data?.progressInPercentage === "1.0"
                          ? true
                          : false,
                    }
                  : { ...e }
              ),
            });
          }
          if (state.authModel.logInUser.role === "Mentee") {
            dispatch.homeModel.setData({
              name: "assignProjectList",
              data: state.homeModel.assignProjectList?.map((e) =>
                e?.enrollId === response?.data?.enrollId
                  ? {
                      ...e,
                      percentageCompleted: (
                        response?.data?.progressInPercentage * 100
                      )
                        .toFixed(2)
                        .replace(/\.00$/, ""),
                    }
                  : { ...e }
              ),
            });
          }
          break;
        }
        case "timezoneUpdate": {
          let auth = cookieDecryption("userDetails");
          auth.timezone = response?.data?.timezone;
          cookieEncryption("userDetails", auth);
          break;
        }

        case "leave": {
          // window.location.reload();
          // state.socketModel.socket.off("disconnect", () => {
          //   console.log('User disconnected')
          // });
        }

        default:
          break;
      }
    },
  }),
};

export default socketModel;

import { useEffect, useRef } from "react";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import {
  Close1,
  Remove_round,
  User,
  setting_drop_arrow,
  personal_pic,
  lock_circle,
  Eye,
  CloseEye,
  Open_eye_icon,
  profile_pic_svg,
  User_fill,
  black_plus,
  plus_profile,
} from "../../../utils/images";
import { cookieDecryption } from "../../../utils";
import store from "../../../rematch";
import { useSelector } from "react-redux";
import { Loader, PlusIcon } from "lucide-react";
import axiosClient from "../../../api/axiosClient";

export default function SettingsPopup({ handleSettingOpen }) {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [attachmentLoading, setattachmentLoading] = useState(false);
  const [view, setView] = useState("Personal Details");
  const { dispatch } = store;
  const userDetails = cookieDecryption("userDetails");
  const { logInUser } = useSelector((state) => state?.authModel);
  const [attachment, setAttachment] = useState(logInUser?.profilePic || "");
  const [attachmentName, setAttachmentName] = useState("");
  const [attachmentError, setAttachmentError] = useState("");
  const attachmentInputRef = useRef();

  useEffect(() => {
    if (logInUser) {
      setValue("first_name", logInUser?.preferredFname);
      setValue("last_name", logInUser?.preferredLname);
      setValue("phone_no", logInUser?.primaryPhoneNo);
      setValue("email", logInUser?.email);
    }
  }, []);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitted, errors },
  } = useForm();
  const {
    register: changePasswordRegister,
    setValue: setChangePassword,
    handleSubmit: changePasswordhandleSubmit,
    control: changePasswordConrol,
    watch: changePasswordWatch,
    setError,
    resetField,
    formState: {
      isSubmitted: changePasswordIsSubmitted,
      errors: changePasswordErrors,
    },
    getFieldState,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });
  const currentPasswordData = getFieldState("currentPassword");
  const newPasswordData = getFieldState("newPassword");
  const firstName = watch("first_name");
  const lastName = watch("last_name");
  const email = watch("email");
  const number = watch("phone_no");

  const currentPassword = changePasswordWatch("currentPassword");
  const newPassword = changePasswordWatch("newPassword");

  const onSubmitPersonalDetails = (data) => {
    let payload = {};
    for (const key in data) {
      payload[key] = data[key].trim();
    }
    dispatch.authModel.userProfileUpdate(payload);
    handleSettingOpen();
  };

  const onSubmitChangePassword = async (data) => {
    const payload = {
      newPassword: data.newPassword,
      currentPassword: data.currentPassword,
    };
    let res = await dispatch.authModel.changePasswordUser({
      payload: payload,
      handleSettingOpen,
    });
    if (res?.state === 400 && res?.error) {
      setError("currentPassword", { type: "custom", message: res?.message });
    }
    // handleSettingOpen()
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleProfileupload({
      target: { files: e.dataTransfer.files },
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    // console.log(e.dataTransfer.items[0], "1");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    // console.log(e.dataTransfer.items[0], "2");
  };
  const handleProfileupload = async (event) => {
    const { name, files } = event.target;
    if (files) {
      const allowedMediaExtensions = ["jpg", "jpeg", "png"];
      const selectedFile = files[0];
      const test = URL.createObjectURL(selectedFile);
      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
      let isMedia = allowedMediaExtensions.includes(`${fileExtension}`);
      if (selectedFile.size > 5 * 1024 * 1024) {
        setAttachmentError(
          "File size is too large. Please upload an image below 5 MB."
        );
        setAttachmentName("");
        setAttachment("");

        attachmentInputRef.current.value = "";
        return;
      }
      if (!isMedia) {
        // dispatch.toastModel.showToast({
        //   value: "Danger",
        //   message: "The type of event attachment must be .jpg/.jpeg/.png/.pdf.",
        // });
        setAttachmentError("The type of attachment must be .jpg/.jpeg/.png.");
        setAttachmentName("");
        setAttachment("");

        attachmentInputRef.current.value = "";
        return;
      } else {
        setAttachmentName(selectedFile);
        setAttachment(test);
        setAttachmentError();
      }
    }
    // setUploads([
    //   ...uploads.filter((file) => file.name !== name),
    //   {
    //     name,
    //     files,
    //   },
    // ]);
  };
  const handleProfilePicSubmit = async (e) => {
    e.preventDefault();
    if (attachmentError) {
      return;
    }
    if (attachment === logInUser?.profilePic) {
      handleSettingOpen();
      return;
    }
    setattachmentLoading(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formData = new FormData();
    formData.append("mentorProfilePic", attachmentName);
    formData.append("mentorId", logInUser?._id);
    await axiosClient
      .post("/auth/uploadUserProfilePic", formData, config)
      .then((res) => {
        if (res.status === 200) {
          setattachmentLoading(false);
          if (res?.data?.data?.profilePic) {
            setAttachment(res?.data?.data?.profilePic);
            dispatch.toastModel.showToast({
              value: "Success",
              message: res?.data?.msg,
            });
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: res?.data?.data,
            });
            handleSettingOpen();
            // setUploadData({ ...uploadData, attachments: files?.[0]?.name });
          } else {
            attachmentInputRef.current.value = "";
            dispatch.toastModel.showToast({
              value: "Success",
              message: res?.data?.msg,
            });
            dispatch.authModel.setUserDetails({
              name: "logInUser",
              data: res?.data?.data,
            });
            handleSettingOpen();
            // setAttachmentError(res?.data?.msg);
          }
        }
      })
      .catch((err) => {
        // dispatch.toastModel.showToast({
        //   value: "Danger",
        //   message: "Invalid Upload",
        // });
      });
  };
  return (
    <div className="modal_comman" style={{ display: "block" }}>
      <div className="delete_modal setting_modal active">
        <div
          className="close_btn d_flex_center"
          onClick={() => handleSettingOpen()}
        >
          <img src={Close1} alt="close_btn" className="w_100" />
        </div>
        <h2>Settings</h2>
        <div className="setting_list">
          <ul>
            <li>
              <form
                className="personal_setting active"
                id="personal details"
                onSubmit={handleSubmit(onSubmitPersonalDetails)}
              >
                <div className="per_info">
                  <div className="per_detials">
                    <span className="per_pic">
                      <img src={personal_pic} alt="personal_pic" />
                    </span>
                    <span className="per_name">Personal Details</span>
                  </div>
                  <div
                    className="per_arrow"
                    onClick={(event) => {
                      event.preventDefault();
                      setView("Personal Details");
                    }}
                  >
                    <span
                      className={`${
                        view === "Personal Details"
                          ? "set_arrow open"
                          : "set_arrow close"
                      }`}
                    >
                      <img src={setting_drop_arrow} alt="setting_drop_arrow" />
                    </span>
                  </div>
                </div>
                {view === "Personal Details" && (
                  <div className="persnol_details">
                    <div className="d_flex_center per_detais">
                      <div className="form_group">
                        <label>First Name</label>
                        <Controller
                          control={control}
                          name="first_name"
                          rules={{
                            required: "Please enter first name",
                            validate: {
                              matchPattern: (value) =>
                                !/[^\p{L}\p{Nd} \-\.]/u.test(value) ||
                                "Please enter correct first name format!",
                            },
                          }}
                          render={({ field }) => {
                            return (
                              <input
                                id="fname"
                                type="text"
                                className={`form_control ${
                                  firstName && "blue_border"
                                }`}
                                placeholder="Enter first name"
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.value.trimStart())
                                }
                              />
                            );
                          }}
                        />

                        {firstName && (
                          <span
                            className="input_fild_remove"
                            onClick={() => setValue("first_name", "")}
                          >
                            <img
                              src={Remove_round}
                              alt="fild_remove_icon"
                              style={{ background: "white" }}
                            />
                          </span>
                        )}
                        {errors?.first_name && (
                          <span className="show_error_msg">
                            {errors?.first_name?.message}
                          </span>
                        )}
                      </div>

                      <div className="form_group">
                        <label>Last Name</label>
                        <Controller
                          control={control}
                          name="last_name"
                          rules={{
                            required: "Please enter last name",
                            validate: {
                              matchPattern: (value) =>
                                !/[^\p{L}\p{Nd} \-\.]/u.test(value) ||
                                "Please enter correct last name format!",
                            },
                          }}
                          render={({ field }) => {
                            return (
                              <input
                                id="lname"
                                type="text"
                                className={`form_control ${
                                  lastName && "blue_border"
                                }`}
                                value={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.value.trimStart())
                                }
                                placeholder="Enter last name"
                              />
                            );
                          }}
                        />

                        {lastName && (
                          <span
                            className="input_fild_remove"
                            onClick={() => setValue("last_name", "")}
                          >
                            <img
                              src={Remove_round}
                              alt="fild_remove_icon"
                              style={{ background: "white" }}
                            />
                          </span>
                        )}
                        {errors?.last_name && (
                          <span className="show_error_msg">
                            {errors?.last_name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d_flex_center per_detais email_requrd">
                      <div className="form_group email_fild">
                        <label>Email</label>
                        <input
                          id="email"
                          type="email"
                          className={`form_control ${email && "blue_border"}`}
                          placeholder="e.g. w.jennings@example.com"
                          {...register("email")}
                          style={{ pointerEvents: true ? "none" : "auto" }}
                        />
                      </div>
                      <div className="form_group phone_fild">
                        <label>Phone</label>
                        <span className="selected-dial-code">+1</span>
                        <Controller
                          control={control}
                          {...register("phone_no", {
                            required: "Please enter phone number",
                            validate: {
                              matchPattern: (value) =>
                                /^[0-9()\-.]+$/.test(value) ||
                                "Please enter valid phone number",
                            },
                          })}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <ReactInputMask
                                id="phone"
                                className={`form_control conform_plus_one ${
                                  number && "blue_border"
                                }`}
                                mask={"(999)-999-9999"}
                                placeholder={"(___)-___-____"}
                                onChange={(e) => onChange(e)}
                                value={value}
                                // style={{pointerEvents : adminUser ? 'none' : 'auto'}}
                              />
                            );
                          }}
                        />
                        {number && (
                          <span
                            className="input_fild_remove"
                            onClick={() => setValue("phone_no", "")}
                          >
                            <img src={Remove_round} alt="fild_remove_icon" />
                          </span>
                        )}
                        {errors?.phone_no && (
                          <span className="show_error_msg">
                            {errors?.phone_no?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </li>
            <li>
              <form
                className="personal_setting"
                id="change password"
                onSubmit={changePasswordhandleSubmit(onSubmitChangePassword)}
              >
                <div className="per_info">
                  <div className="per_detials">
                    <span className="per_pic">
                      <img src={lock_circle} alt="personal_pic" />
                    </span>
                    <span className="per_name">Change Password</span>
                  </div>
                  <div
                    className="per_arrow"
                    onClick={(event) => {
                      event.preventDefault();
                      setView("Change Password");
                    }}
                  >
                    <span
                      className={`${
                        view === "Change Password"
                          ? "set_arrow open"
                          : "set_arrow close"
                      }`}
                    >
                      <img src={setting_drop_arrow} alt="setting_drop_arrow" />
                    </span>
                  </div>
                </div>
                {view === "Change Password" && (
                  <div className="persnol_details">
                    <div className="d_flex_center per_detais">
                      <div className="form_group">
                        <label>Enter Current Password</label>
                        <input
                          id="password"
                          type={isPasswordVisible ? "text" : "password"}
                          placeholder="*********"
                          autoComplete="new-password"
                          className={`form_control ${
                            changePasswordIsSubmitted &&
                            (changePasswordErrors.currentPassword?.type ===
                              "required" ||
                            changePasswordErrors.currentPassword?.type ===
                              "minLength" ||
                            changePasswordErrors.currentPassword?.type ===
                              "matchPattern" ||
                            changePasswordErrors.currentPassword?.type ===
                              "custom"
                              ? "border_red"
                              : `${
                                  currentPasswordData.isDirty
                                    ? "border_green"
                                    : ""
                                }`)
                          }`}
                          {...changePasswordRegister("currentPassword", {
                            required: "Please enter current password",
                            minLength: {
                              value: 8,
                              message: "Password must be at least 8 characters",
                            },
                            // validate: {
                            //   matchPattern: (value) =>
                            //     /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(value) ||
                            //     // /^(?=.[A-Z])(?=.\d)(?=.[!@#$%^&()_+]).{8,}$/.test(value) ||
                            //     "Password must include at least one capital letter, one special character and one digit.",
                            // },
                            // validate: {
                            //   matchPattern: (value) =>
                            //   /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+]).{8,}$/.test(value) ||
                            //     "Password must include at least one capital letter, one special character and one digit.",
                            // },
                          })}
                        />
                        {currentPassword && (
                          <span className="eye_pass">
                            <img
                              src={isPasswordVisible ? CloseEye : Open_eye_icon}
                              alt="eye"
                              onClick={() =>
                                setPasswordVisible(!isPasswordVisible)
                              }
                            />
                          </span>
                        )}
                        {currentPassword && (
                          <span
                            className="input_fild_remove setting_popup_close_icon"
                            onClick={() => {
                              setChangePassword("currentPassword", "");
                              resetField("currentPassword");
                            }}
                          >
                            <img src={Remove_round} alt="fild_remove_icon" />
                          </span>
                        )}
                        {changePasswordErrors?.currentPassword && (
                          <span className="show_error_msg">
                            {changePasswordErrors?.currentPassword?.message}
                          </span>
                        )}
                      </div>

                      <div className="form_group">
                        <label>Enter New Password</label>
                        <input
                          id="newPassword"
                          type={isNewPasswordVisible ? "text" : "password"}
                          placeholder="*********"
                          className={`form_control ${
                            changePasswordIsSubmitted &&
                            (changePasswordErrors.newPassword?.type ===
                              "required" ||
                            changePasswordErrors.newPassword?.type ===
                              "matchPattern" ||
                            changePasswordErrors.newPassword?.type ===
                              "minLength" ||
                            changePasswordErrors.newPassword?.type === "custom"
                              ? "border_red"
                              : `${
                                  newPasswordData.isDirty ? "border_green" : ""
                                }`)
                          }`}
                          {...changePasswordRegister("newPassword", {
                            required: "Please enter new password",
                            minLength: {
                              value: 8,
                              message: "Password must be at least 8 characters",
                            },
                            validate: {
                              matchPattern: (value) =>
                                // /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(value) ||
                                /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+]).{8,}$/.test(
                                  value
                                ) ||
                                "Password must include at least one capital letter, one special character and one digit.",
                            },
                          })}
                        />
                        {newPassword && (
                          <span className="eye_pass">
                            <img
                              src={
                                isNewPasswordVisible ? CloseEye : Open_eye_icon
                              }
                              alt="eye"
                              onClick={() =>
                                setNewPasswordVisible(!isNewPasswordVisible)
                              }
                            />
                          </span>
                        )}
                        {newPassword && (
                          <span
                            className="input_fild_remove setting_popup_close_icon"
                            onClick={() => {
                              setChangePassword("newPassword", "");
                              resetField("newPassword");
                            }}
                          >
                            <img src={Remove_round} alt="fild_remove_icon" />
                          </span>
                        )}
                        {changePasswordErrors?.newPassword && (
                          <span className="show_error_msg">
                            {changePasswordErrors?.newPassword?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </li>

            <li>
              <form
                className="personal_setting"
                id="upload profile"
                onSubmit={handleProfilePicSubmit}
              >
                <div className="per_info">
                  <div className="per_detials">
                    <span className="per_pic">
                      <img src={profile_pic_svg} alt="personal_pic" />
                    </span>
                    <span className="per_name">Profile Picture</span>
                  </div>
                  <div
                    className="per_arrow"
                    onClick={(event) => {
                      event.preventDefault();
                      setView("Upload Profile");
                    }}
                  >
                    <span
                      className={`${
                        view === "Change Password"
                          ? "set_arrow open"
                          : "set_arrow close"
                      }`}
                    >
                      <img src={setting_drop_arrow} alt="setting_drop_arrow" />
                    </span>
                  </div>
                </div>
                {view === "Upload Profile" && (
                  <>
                    <div
                      class="main_update_profile bg_sky_blue p_24 m_24 "
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <div className="d_flex_between">
                        <div class="left_sec flex items-center gap-[2.4rem] position_relative">
                          <div class="round_pic">
                            <span className="setting_profile d_flex_center bg_blue_profile">
                              <img
                                src={attachment !== "" ? attachment : User_fill}
                                alt=""
                              />
                            </span>
                          </div>
                          <div class="content">
                            <p>
                              <span className="fw_700">Drag</span> to upload
                              your profile photo (max 5 MB) (.jpg, .png){" "}
                            </p>
                          </div>
                          <input
                            type="file"
                            className="position_absolute w-0 h-0 opacity-0 z-10"
                            id="file"
                            accept=".jpg, .png"
                            onChange={handleProfileupload}
                          />
                        </div>

                        <div class="right_sec d_flex flex_direction_column gap_20">
                          <div class="button_upload relative">
                            <button
                              type={"button"}
                              className="upload_img upload_pic relative z-1"
                              onClick={() =>
                                attachmentInputRef?.current?.click()
                              }
                            >
                              {attachment === ""
                                ? "Upload Photo"
                                : "Change Photo"}
                              {attachment === "" && (
                                <img src={plus_profile} alt={"black_plus"} />
                              )}
                            </button>
                            <input
                              ref={attachmentInputRef}
                              type="file"
                              id="file"
                              onChange={handleProfileupload}
                              accept=".jpg, .png"
                              className="position_absolute top-[0] left-0 w-full h-full opacity-0 z-10 cursor-pointer d_none"
                            />
                          </div>
                          {attachment !== "" && (
                            <button
                              type={"button"}
                              className="btn_remove"
                              onClick={() => setAttachment("")}
                            >
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                      {attachmentError && (
                        <span
                          style={{ paddingLeft: "4rem", paddingTop: "1rem" }}
                          className="show_error_msg text-center"
                        >
                          {attachmentError}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </form>
            </li>
          </ul>
        </div>
        <div className="d_flex_center upload_btn">
          {view === "Personal Details" ? (
            <button
              className="btn primary_btn blue_btn"
              key="pre"
              type="submit"
              form={"personal details"}
            >
              Save Changes
            </button>
          ) : (
            <>
              {view === "Change Password" ? (
                <button
                  className="btn primary_btn blue_btn"
                  key="chang"
                  type="submit"
                  form={"change password"}
                >
                  Save Changes
                </button>
              ) : (
                <>
                  <button
                    className="btn primary_btn blue_btn"
                    key="upload"
                    type="submit"
                    form={"upload profile"}
                    disabled={attachmentLoading}
                    f
                  >
                    {attachmentLoading && (
                      <Loader className="animate-spin mr-3" />
                    )}
                    Save Changes
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
